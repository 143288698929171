import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export class Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A calendar date that does include a time component. */
  DateTime: any;
  /** A 64-bit, floating-point value. (Min: -1.7976931348623157E+308, Max: 1.7976931348623157E+308) */
  Double: any;
  /** A standard guid (e.g. '6dd43342-ffe6-4964-bb6f-e31c8e50ec86'). */
  Guid: any;
  /** A time of day that does not include a date component. */
  TimeOnly: any;
};

export class AnswerPagination {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Maybe<AnswerVm>>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class AnswerVm {
  answer?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Guid']>;
  image_url?: Maybe<Scalars['String']>;
  is_correct?: Maybe<Scalars['Boolean']>;
  ordination?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['Guid']>;
  question_title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export class CampaignEntity {
  created_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  finished_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Guid']>;
  informative_title?: Maybe<Scalars['String']>;
  initials?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  is_finished?: Maybe<Scalars['Boolean']>;
  owner?: Maybe<Scalars['Int']>;
  participate_title?: Maybe<Scalars['String']>;
  path_image?: Maybe<Scalars['String']>;
  path_image_banner?: Maybe<Scalars['String']>;
  path_image_banner_mobile?: Maybe<Scalars['String']>;
  path_image_call?: Maybe<Scalars['String']>;
  path_image_mobile?: Maybe<Scalars['String']>;
  path_regulation?: Maybe<Scalars['String']>;
  primary_color?: Maybe<Scalars['String']>;
  secondary_color?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  update_at?: Maybe<Scalars['DateTime']>;
};

export class CampaignPagination {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Maybe<CampaignVm>>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class CampaignVm {
  active?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  finished?: Maybe<Scalars['Boolean']>;
  finished_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Guid']>;
  informative_title?: Maybe<Scalars['String']>;
  initials?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['Int']>;
  participate_title?: Maybe<Scalars['String']>;
  path_image?: Maybe<Scalars['String']>;
  path_image_banner?: Maybe<Scalars['String']>;
  path_image_banner_mobile?: Maybe<Scalars['String']>;
  path_image_call?: Maybe<Scalars['String']>;
  path_image_mobile?: Maybe<Scalars['String']>;
  path_regulation?: Maybe<Scalars['String']>;
  primary_color?: Maybe<Scalars['String']>;
  secondary_color?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
};

export class DefaultMessage {
  message?: Maybe<Scalars['String']>;
};

export class GameAnswers {
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Guid']>;
  isCorrect?: Maybe<Scalars['Boolean']>;
  text?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export class GameQuestions {
  answers?: Maybe<Array<Maybe<GameAnswers>>>;
  gameHeader?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Guid']>;
  module?: Maybe<Scalars['String']>;
};

export class GameVm {
  games?: Maybe<Array<Maybe<GameQuestions>>>;
  id?: Maybe<Scalars['Guid']>;
  module?: Maybe<Scalars['String']>;
  quiz_id?: Maybe<Scalars['Guid']>;
};

export class Input_AnswerFilterInput {
  answer?: InputMaybe<Scalars['String']>;
  page?: Scalars['Int'];
  question_id?: InputMaybe<Scalars['String']>;
};

export class Input_CampaignFilterInput {
  finished_at?: InputMaybe<Scalars['DateTime']>;
  page?: Scalars['Int'];
  started_at?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export class Input_ChancePasswordInput {
  password?: InputMaybe<Scalars['String']>;
  passwordOld?: InputMaybe<Scalars['String']>;
};

export class Input_CorrectList {
  id?: InputMaybe<Scalars['Guid']>;
  isCorrect?: InputMaybe<Scalars['Boolean']>;
};

export class Input_CreateAnswerVm {
  answer?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  image_url?: InputMaybe<Scalars['String']>;
  is_correct?: InputMaybe<Scalars['Boolean']>;
  ordination?: InputMaybe<Scalars['Int']>;
  question_id?: InputMaybe<Scalars['Guid']>;
  width?: InputMaybe<Scalars['Int']>;
};

export class Input_CreateCampaignVm {
  description?: InputMaybe<Scalars['String']>;
  finished_at?: InputMaybe<Scalars['DateTime']>;
  informative_title?: InputMaybe<Scalars['String']>;
  initials?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['Int']>;
  participate_title?: InputMaybe<Scalars['String']>;
  path_image?: InputMaybe<Scalars['String']>;
  path_image_banner?: InputMaybe<Scalars['String']>;
  path_image_banner_mobile?: InputMaybe<Scalars['String']>;
  path_image_call?: InputMaybe<Scalars['String']>;
  path_image_mobile?: InputMaybe<Scalars['String']>;
  path_regulation?: InputMaybe<Scalars['String']>;
  primary_color?: InputMaybe<Scalars['String']>;
  secondary_color?: InputMaybe<Scalars['String']>;
  started_at?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export class Input_CreateQuestionVm {
  answers?: InputMaybe<Array<InputMaybe<Input_CreateAnswerVm>>>;
  module?: InputMaybe<Scalars['String']>;
  module_level?: InputMaybe<Scalars['String']>;
  punctuation?: InputMaybe<Scalars['Int']>;
  punctuation_second?: InputMaybe<Scalars['Int']>;
  question?: InputMaybe<Scalars['String']>;
  questionnaire_id?: InputMaybe<Scalars['Guid']>;
};

export class Input_CreateQuestionnaireVm {
  finished_at?: InputMaybe<Scalars['DateTime']>;
  module?: InputMaybe<Scalars['String']>;
  ordination?: InputMaybe<Scalars['Int']>;
  punctuation?: InputMaybe<Scalars['Int']>;
  questions?: InputMaybe<Array<InputMaybe<Input_CreateQuestionVm>>>;
  quiz_id?: InputMaybe<Scalars['Guid']>;
  response_time?: InputMaybe<Scalars['TimeOnly']>;
  started_at?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export class Input_CreateQuizVm {
  campaign_id?: InputMaybe<Scalars['Guid']>;
  description?: InputMaybe<Scalars['String']>;
  finished_at?: InputMaybe<Scalars['String']>;
  friendly_url?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_finished?: InputMaybe<Scalars['Boolean']>;
  is_second_chance?: InputMaybe<Scalars['Boolean']>;
  path_banner?: InputMaybe<Scalars['String']>;
  path_banner_mobile?: InputMaybe<Scalars['String']>;
  path_image?: InputMaybe<Scalars['String']>;
  path_image_mobile?: InputMaybe<Scalars['String']>;
  started_at?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export class Input_CreateUserAdminVm {
  birthday?: InputMaybe<Scalars['DateTime']>;
  cpf?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nickName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  photoUrl?: InputMaybe<Scalars['String']>;
};

export class Input_CreateUserVm {
  address?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  cellphone?: InputMaybe<Scalars['String']>;
  cellphone2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  document?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  neighborhood?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export class Input_GamePostQuestion {
  correctList?: InputMaybe<Array<InputMaybe<Input_CorrectList>>>;
  generalData?: InputMaybe<Input_GeneralData>;
  incorrectList?: InputMaybe<Array<InputMaybe<Input_IncorrectList>>>;
};

export class Input_GamePostVm {
  directToNextBase?: InputMaybe<Scalars['Boolean']>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Guid']>;
  module?: InputMaybe<Scalars['String']>;
  percentualTotal?: InputMaybe<Scalars['Double']>;
  questions?: InputMaybe<Array<InputMaybe<Input_GamePostQuestion>>>;
  quiz_id?: InputMaybe<Scalars['Guid']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['Guid']>;
};

export class Input_GeneralData {
  id?: InputMaybe<Scalars['Guid']>;
  numberOfCorrect?: InputMaybe<Scalars['Int']>;
  numberOfWrong?: InputMaybe<Scalars['Int']>;
  percentualScore?: InputMaybe<Scalars['Double']>;
  secondsToFinish?: InputMaybe<Scalars['Double']>;
  totalElements?: InputMaybe<Scalars['Int']>;
};

export class Input_IncorrectList {
  id?: InputMaybe<Scalars['Guid']>;
  isCorrect?: InputMaybe<Scalars['Boolean']>;
};

export class Input_InitialAccessVm {
  questions?: InputMaybe<Array<InputMaybe<Input_InitialQuestionsVm>>>;
  userId?: Scalars['Guid'];
};

export class Input_InitialAnswersVm {
  id?: InputMaybe<Scalars['Int']>;
  selected?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
};

export class Input_InitialQuestionsVm {
  answers?: InputMaybe<Array<InputMaybe<Input_InitialAnswersVm>>>;
  id?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
};

export class Input_QuestionFilterInput {
  page?: Scalars['Int'];
  question?: InputMaybe<Scalars['String']>;
  questionnaire_id?: InputMaybe<Scalars['String']>;
};

export class Input_QuizAccessVm {
  browser?: InputMaybe<Scalars['String']>;
  ip_origin?: InputMaybe<Scalars['String']>;
  quiz_id?: Scalars['Guid'];
  resolution?: InputMaybe<Scalars['String']>;
  server_origin?: InputMaybe<Scalars['String']>;
  url_origin?: InputMaybe<Scalars['String']>;
  user_id?: Scalars['Guid'];
};

export class Input_QuizListFilterInput {
  campaign_id?: InputMaybe<Scalars['String']>;
  finished_at?: InputMaybe<Scalars['DateTime']>;
  page?: Scalars['Int'];
  started_at?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export class Input_QuizQuestionnaireFilter {
  finished_at?: InputMaybe<Scalars['DateTime']>;
  page?: Scalars['Int'];
  quiz_id?: InputMaybe<Scalars['String']>;
  started_at?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export class Input_ResetPasswordInput {
  code?: InputMaybe<Scalars['String']>;
  newPassword?: InputMaybe<Scalars['String']>;
};

export class Input_UpdateAnswerVm {
  answer?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Guid']>;
  image_url?: InputMaybe<Scalars['String']>;
  is_correct?: InputMaybe<Scalars['Boolean']>;
  ordination?: InputMaybe<Scalars['Int']>;
  question_id?: InputMaybe<Scalars['Guid']>;
  width?: InputMaybe<Scalars['Int']>;
};

export class Input_UpdateCampaignVm {
  description?: InputMaybe<Scalars['String']>;
  finished_at?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Guid']>;
  informative_title?: InputMaybe<Scalars['String']>;
  initials?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['Int']>;
  participate_title?: InputMaybe<Scalars['String']>;
  path_image?: InputMaybe<Scalars['String']>;
  path_image_banner?: InputMaybe<Scalars['String']>;
  path_image_banner_mobile?: InputMaybe<Scalars['String']>;
  path_image_call?: InputMaybe<Scalars['String']>;
  path_image_mobile?: InputMaybe<Scalars['String']>;
  path_regulation?: InputMaybe<Scalars['String']>;
  primary_color?: InputMaybe<Scalars['String']>;
  secondary_color?: InputMaybe<Scalars['String']>;
  started_at?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export class Input_UpdateQuestionVm {
  answers?: InputMaybe<Array<InputMaybe<Input_UpdateAnswerVm>>>;
  id?: InputMaybe<Scalars['Guid']>;
  module?: InputMaybe<Scalars['String']>;
  module_level?: InputMaybe<Scalars['String']>;
  punctuation?: InputMaybe<Scalars['Int']>;
  punctuation_second?: InputMaybe<Scalars['Int']>;
  question?: InputMaybe<Scalars['String']>;
  questionnaire_id?: InputMaybe<Scalars['Guid']>;
};

export class Input_UpdateQuestionnaireVm {
  finished_at?: InputMaybe<Scalars['DateTime']>;
  id?: Scalars['Guid'];
  module?: InputMaybe<Scalars['String']>;
  ordination?: InputMaybe<Scalars['Int']>;
  punctuation?: InputMaybe<Scalars['Int']>;
  questions?: InputMaybe<Array<InputMaybe<Input_UpdateQuestionVm>>>;
  quiz_id?: InputMaybe<Scalars['Guid']>;
  response_time?: InputMaybe<Scalars['TimeOnly']>;
  started_at?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export class Input_UpdateQuizVm {
  campaign_id?: InputMaybe<Scalars['Guid']>;
  description?: InputMaybe<Scalars['String']>;
  finished_at?: InputMaybe<Scalars['DateTime']>;
  friendly_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Guid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_finished?: InputMaybe<Scalars['Boolean']>;
  is_second_chance?: InputMaybe<Scalars['Boolean']>;
  path_banner?: InputMaybe<Scalars['String']>;
  path_banner_mobile?: InputMaybe<Scalars['String']>;
  path_image?: InputMaybe<Scalars['String']>;
  path_image_mobile?: InputMaybe<Scalars['String']>;
  started_at?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export class Input_UpdateUserAdminVm {
  birthday?: InputMaybe<Scalars['DateTime']>;
  cpf?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Guid']>;
  name?: InputMaybe<Scalars['String']>;
  nickName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  photoUrl?: InputMaybe<Scalars['String']>;
};

export class Input_UpdateUserVm {
  address?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  cellphone?: InputMaybe<Scalars['String']>;
  cellphone2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  document?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Guid']>;
  name?: InputMaybe<Scalars['String']>;
  neighborhood?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export class Input_UserAdminFilterInput {
  email?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: Scalars['Int'];
  start?: InputMaybe<Scalars['DateTime']>;
};

export class Input_UserAnswerFilter {
  answer?: InputMaybe<Scalars['Guid']>;
  page?: Scalars['Int'];
  question?: InputMaybe<Scalars['Guid']>;
  questionnaire?: InputMaybe<Scalars['Guid']>;
  right_answer?: InputMaybe<Scalars['Boolean']>;
  user?: InputMaybe<Scalars['String']>;
};

export class Input_UserAnswerInsert {
  answer_id?: InputMaybe<Scalars['Guid']>;
  answered?: InputMaybe<Scalars['Boolean']>;
  answered_at?: InputMaybe<Scalars['DateTime']>;
  ordination?: InputMaybe<Scalars['Int']>;
  question_id?: InputMaybe<Scalars['Guid']>;
  quiz_questionnaire_id?: InputMaybe<Scalars['Guid']>;
  right_answer?: InputMaybe<Scalars['Boolean']>;
  right_answer_tentative?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Guid']>;
};

export class Input_UserFilterInput {
  city?: InputMaybe<Scalars['String']>;
  document?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  page?: Scalars['Int'];
  state?: InputMaybe<Scalars['String']>;
};

export class Input_UserQuestionnaireFilter {
  finished_at?: InputMaybe<Scalars['DateTime']>;
  page?: Scalars['Int'];
  questionnaire?: InputMaybe<Scalars['Guid']>;
  quiz?: InputMaybe<Scalars['Guid']>;
  started_at?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['Boolean']>;
  user?: InputMaybe<Scalars['String']>;
};

export class Input_UserQuestionnaireInsert {
  finish?: InputMaybe<Scalars['DateTime']>;
  is_finished?: InputMaybe<Scalars['Boolean']>;
  is_first?: InputMaybe<Scalars['Boolean']>;
  is_time_out?: InputMaybe<Scalars['Boolean']>;
  punctuation?: InputMaybe<Scalars['Int']>;
  questionnaire_module?: InputMaybe<Scalars['String']>;
  quiz_id?: InputMaybe<Scalars['Guid']>;
  quiz_questionnaire_id?: InputMaybe<Scalars['Guid']>;
  start?: InputMaybe<Scalars['DateTime']>;
  time_out_at?: InputMaybe<Scalars['DateTime']>;
  user_id?: InputMaybe<Scalars['Guid']>;
};

export class Input_UserVm {
  address?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  cellphone?: InputMaybe<Scalars['String']>;
  cellphone2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  document?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first_access?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Guid']>;
  name?: InputMaybe<Scalars['String']>;
  neighborhood?: InputMaybe<Scalars['String']>;
  profile_type?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export class Mutation {
  changePassword?: Maybe<DefaultMessage>;
  createAnswer?: Maybe<UpdateAnswerVm>;
  createCampaign?: Maybe<DefaultMessage>;
  createQuestion?: Maybe<UpdateQuestionVm>;
  createQuestionnaire?: Maybe<DefaultMessage>;
  createQuiz?: Maybe<DefaultMessage>;
  createUser?: Maybe<DefaultMessage>;
  createUserAdmin?: Maybe<DefaultMessage>;
  deleteAnswer?: Maybe<DefaultMessage>;
  deleteCampaign?: Maybe<DefaultMessage>;
  deleteQuestion?: Maybe<DefaultMessage>;
  deleteQuestionnaire?: Maybe<DefaultMessage>;
  deleteQuiz?: Maybe<DefaultMessage>;
  deleteUser?: Maybe<DefaultMessage>;
  deleteUserAdmin?: Maybe<DefaultMessage>;
  firstAccess?: Maybe<UserVm>;
  forgotPassword?: Maybe<DefaultMessage>;
  initialAccess?: Maybe<User>;
  insertFirstAccess?: Maybe<UserVm>;
  insertQuizAccess: Scalars['Boolean'];
  insertUserAnswer?: Maybe<DefaultMessage>;
  insertUserQuestionnaire?: Maybe<DefaultMessage>;
  login?: Maybe<User>;
  loginAdmin?: Maybe<UserAdmin>;
  postGame?: Maybe<DefaultMessage>;
  resetPassword?: Maybe<DefaultMessage>;
  updateAnswer?: Maybe<UpdateAnswerVm>;
  updateCampaign?: Maybe<UpdateCampaignVm>;
  updateQuestion?: Maybe<UpdateQuestionVm>;
  updateQuestionnaire?: Maybe<QuestionnaireVm>;
  updateQuiz?: Maybe<QuizListVm>;
  updateUser?: Maybe<UpdateUserVm>;
  updateUserAdmin?: Maybe<UpdateUserAdminVm>;
};


export class MutationChangePasswordArgs {
  input?: InputMaybe<Input_ChancePasswordInput>;
};


export class MutationCreateAnswerArgs {
  input?: InputMaybe<Input_CreateAnswerVm>;
};


export class MutationCreateCampaignArgs {
  input?: InputMaybe<Input_CreateCampaignVm>;
};


export class MutationCreateQuestionArgs {
  input?: InputMaybe<Input_CreateQuestionVm>;
};


export class MutationCreateQuestionnaireArgs {
  input?: InputMaybe<Input_CreateQuestionnaireVm>;
};


export class MutationCreateQuizArgs {
  input?: InputMaybe<Input_CreateQuizVm>;
};


export class MutationCreateUserArgs {
  input?: InputMaybe<Input_CreateUserVm>;
};


export class MutationCreateUserAdminArgs {
  input?: InputMaybe<Input_CreateUserAdminVm>;
};


export class MutationDeleteAnswerArgs {
  id: Scalars['Guid'];
};


export class MutationDeleteCampaignArgs {
  id: Scalars['Guid'];
};


export class MutationDeleteQuestionArgs {
  id: Scalars['Guid'];
};


export class MutationDeleteQuestionnaireArgs {
  id: Scalars['Guid'];
};


export class MutationDeleteQuizArgs {
  id: Scalars['Guid'];
};


export class MutationDeleteUserArgs {
  id: Scalars['Guid'];
};


export class MutationDeleteUserAdminArgs {
  id: Scalars['Guid'];
};


export class MutationFirstAccessArgs {
  email?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};


export class MutationForgotPasswordArgs {
  email?: InputMaybe<Scalars['String']>;
};


export class MutationInitialAccessArgs {
  input?: InputMaybe<Input_InitialAccessVm>;
};


export class MutationInsertFirstAccessArgs {
  input?: InputMaybe<Input_UserVm>;
};


export class MutationInsertQuizAccessArgs {
  input?: InputMaybe<Input_QuizAccessVm>;
};


export class MutationInsertUserAnswerArgs {
  input?: InputMaybe<Input_UserAnswerInsert>;
};


export class MutationInsertUserQuestionnaireArgs {
  input?: InputMaybe<Input_UserQuestionnaireInsert>;
};


export class MutationLoginArgs {
  cpf?: InputMaybe<Scalars['String']>;
};


export class MutationLoginAdminArgs {
  cpf?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};


export class MutationPostGameArgs {
  game?: InputMaybe<Input_GamePostVm>;
};


export class MutationResetPasswordArgs {
  input?: InputMaybe<Input_ResetPasswordInput>;
};


export class MutationUpdateAnswerArgs {
  input?: InputMaybe<Input_UpdateAnswerVm>;
};


export class MutationUpdateCampaignArgs {
  input?: InputMaybe<Input_UpdateCampaignVm>;
};


export class MutationUpdateQuestionArgs {
  input?: InputMaybe<Input_UpdateQuestionVm>;
};


export class MutationUpdateQuestionnaireArgs {
  input?: InputMaybe<Input_UpdateQuestionnaireVm>;
};


export class MutationUpdateQuizArgs {
  input?: InputMaybe<Input_UpdateQuizVm>;
};


export class MutationUpdateUserArgs {
  input?: InputMaybe<Input_UpdateUserVm>;
};


export class MutationUpdateUserAdminArgs {
  input?: InputMaybe<Input_UpdateUserAdminVm>;
};

export class Query {
  currentUser?: Maybe<UserVm>;
  currentUserAdmin?: Maybe<UserAdminVm>;
  getAnswer?: Maybe<Array<Maybe<QuestionnaireAnswerEntity>>>;
  getAnswerById?: Maybe<AnswerVm>;
  getAnswerFilter?: Maybe<AnswerPagination>;
  getCampaign?: Maybe<Array<Maybe<CampaignEntity>>>;
  getCampaignById?: Maybe<CampaignVm>;
  getCampaignFilter?: Maybe<CampaignPagination>;
  getGame?: Maybe<GameVm>;
  getQuestion?: Maybe<Array<Maybe<QuestionnaireQuestionEntity>>>;
  getQuestionById?: Maybe<QuestionVm>;
  getQuestionFilter?: Maybe<QuestionPagination>;
  getQuestionnaire?: Maybe<Array<Maybe<QuizQuestionnaireVm>>>;
  getQuestionnaireById?: Maybe<QuestionnaireVm>;
  getQuestionnaireFilter?: Maybe<QuizQuestionnairePagination>;
  getQuiz?: Maybe<Array<Maybe<QuizEntity>>>;
  getQuizById?: Maybe<QuizListVm>;
  getQuizFilter?: Maybe<QuizPagination>;
  getUser?: Maybe<Array<Maybe<UserEntity>>>;
  getUserAdmin?: Maybe<Array<Maybe<UserAdminEntity>>>;
  getUserAdminById?: Maybe<UserAdminVm>;
  getUserById?: Maybe<UserVm>;
  getUserFilter?: Maybe<UserPagination>;
  questionnaireByQuizId?: Maybe<QuizQuestionnairePagination>;
  userAnswerFilter?: Maybe<UserAnswerPagination>;
  userProgress?: Maybe<UserProgressVm>;
  userQuestionnaireFilter?: Maybe<UserQuestionnairePagination>;
  usersAdmin?: Maybe<UserAdminPagination>;
};


export class QueryGetAnswerByIdArgs {
  id: Scalars['Guid'];
};


export class QueryGetAnswerFilterArgs {
  input?: InputMaybe<Input_AnswerFilterInput>;
};


export class QueryGetCampaignByIdArgs {
  id: Scalars['Guid'];
};


export class QueryGetCampaignFilterArgs {
  input?: InputMaybe<Input_CampaignFilterInput>;
};


export class QueryGetGameArgs {
  module?: InputMaybe<Scalars['String']>;
};


export class QueryGetQuestionByIdArgs {
  id: Scalars['Guid'];
};


export class QueryGetQuestionFilterArgs {
  input?: InputMaybe<Input_QuestionFilterInput>;
};


export class QueryGetQuestionnaireByIdArgs {
  id: Scalars['Guid'];
};


export class QueryGetQuestionnaireFilterArgs {
  input?: InputMaybe<Input_QuizQuestionnaireFilter>;
};


export class QueryGetQuizByIdArgs {
  id: Scalars['Guid'];
};


export class QueryGetQuizFilterArgs {
  input?: InputMaybe<Input_QuizListFilterInput>;
};


export class QueryGetUserAdminByIdArgs {
  id: Scalars['Guid'];
};


export class QueryGetUserByIdArgs {
  id: Scalars['Guid'];
};


export class QueryGetUserFilterArgs {
  input?: InputMaybe<Input_UserFilterInput>;
};


export class QueryQuestionnaireByQuizIdArgs {
  page: Scalars['Int'];
  quizId: Scalars['Guid'];
};


export class QueryUserAnswerFilterArgs {
  input?: InputMaybe<Input_UserAnswerFilter>;
};


export class QueryUserProgressArgs {
  userId: Scalars['Guid'];
};


export class QueryUserQuestionnaireFilterArgs {
  input?: InputMaybe<Input_UserQuestionnaireFilter>;
};


export class QueryUsersAdminArgs {
  input?: InputMaybe<Input_UserAdminFilterInput>;
};

export class QuestionPagination {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Maybe<QuestionVm>>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class QuestionVm {
  id?: Maybe<Scalars['Guid']>;
  module?: Maybe<Scalars['String']>;
  module_level?: Maybe<Scalars['String']>;
  punctuation?: Maybe<Scalars['Int']>;
  punctuation_second?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  questionnaire_id?: Maybe<Scalars['Guid']>;
  questionnaire_title?: Maybe<Scalars['String']>;
};

export class QuestionnaireAnswerEntity {
  answer?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Guid']>;
  image_url?: Maybe<Scalars['String']>;
  is_correct?: Maybe<Scalars['Boolean']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  ordination?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['Guid']>;
  update_at?: Maybe<Scalars['DateTime']>;
  width?: Maybe<Scalars['Int']>;
};

export class QuestionnaireAnswerVm {
  answer?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Guid']>;
  image_url?: Maybe<Scalars['String']>;
  is_correct?: Maybe<Scalars['Boolean']>;
  ordination?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['Guid']>;
  question_title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export class QuestionnaireQuestionEntity {
  created_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['Guid'];
  is_deleted?: Maybe<Scalars['Boolean']>;
  is_second_chance?: Maybe<Scalars['Boolean']>;
  module?: Maybe<Scalars['String']>;
  module_level?: Maybe<Scalars['String']>;
  punctuation?: Maybe<Scalars['Int']>;
  punctuation_second?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  questionnaire_id?: Maybe<Scalars['Guid']>;
  update_at?: Maybe<Scalars['DateTime']>;
};

export class QuestionnaireQuestionVm {
  answers?: Maybe<Array<Maybe<QuestionnaireAnswerVm>>>;
  id?: Maybe<Scalars['Guid']>;
  module?: Maybe<Scalars['String']>;
  module_level?: Maybe<Scalars['String']>;
  punctuation?: Maybe<Scalars['Int']>;
  punctuation_second?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  questionnaire_id?: Maybe<Scalars['Guid']>;
  questionnaire_title?: Maybe<Scalars['String']>;
};

export class QuestionnaireVm {
  finished_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Guid']>;
  module?: Maybe<Scalars['String']>;
  ordination?: Maybe<Scalars['Int']>;
  punctuation?: Maybe<Scalars['Int']>;
  questions?: Maybe<Array<Maybe<QuestionnaireQuestionVm>>>;
  quiz_id?: Maybe<Scalars['Guid']>;
  response_time?: Maybe<Scalars['TimeOnly']>;
  started_at?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
};

export class QuizEntity {
  campaign_id?: Maybe<Scalars['Guid']>;
  created_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  finished_at?: Maybe<Scalars['DateTime']>;
  friendly_url?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  is_active?: Maybe<Scalars['Boolean']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  is_finished?: Maybe<Scalars['Boolean']>;
  is_second_chance?: Maybe<Scalars['Boolean']>;
  path_image?: Maybe<Scalars['String']>;
  path_image_banner?: Maybe<Scalars['String']>;
  path_image_banner_mobile?: Maybe<Scalars['String']>;
  path_image_mobile?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  update_at?: Maybe<Scalars['DateTime']>;
};

export class QuizListVm {
  active?: Maybe<Scalars['Boolean']>;
  campaign_id?: Maybe<Scalars['Guid']>;
  campaign_title?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  finished?: Maybe<Scalars['Boolean']>;
  finished_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Guid']>;
  is_second_chance?: Maybe<Scalars['Boolean']>;
  path_image?: Maybe<Scalars['String']>;
  path_image_banner?: Maybe<Scalars['String']>;
  path_image_banner_mobile?: Maybe<Scalars['String']>;
  path_image_mobile?: Maybe<Scalars['String']>;
  path_link?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
};

export class QuizPagination {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Maybe<QuizListVm>>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class QuizQuestionnaireListVm {
  finished_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Guid']>;
  ordination?: Maybe<Scalars['Int']>;
  punctuation?: Maybe<Scalars['Int']>;
  quiz_id?: Maybe<Scalars['Guid']>;
  quiz_title?: Maybe<Scalars['String']>;
  response_time?: Maybe<Scalars['TimeOnly']>;
  started_at?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
};

export class QuizQuestionnairePagination {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Maybe<QuizQuestionnaireListVm>>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class QuizQuestionnaireVm {
  finished_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Guid']>;
  ordination?: Maybe<Scalars['Int']>;
  punctuation?: Maybe<Scalars['Int']>;
  quiz_id?: Maybe<Scalars['Guid']>;
  response_time?: Maybe<Scalars['TimeOnly']>;
  started_at?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
};

export class UpdateAnswerVm {
  answer?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Guid']>;
  image_url?: Maybe<Scalars['String']>;
  is_correct?: Maybe<Scalars['Boolean']>;
  ordination?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['Guid']>;
  width?: Maybe<Scalars['Int']>;
};

export class UpdateCampaignVm {
  description?: Maybe<Scalars['String']>;
  finished_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Guid']>;
  informative_title?: Maybe<Scalars['String']>;
  initials?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['Int']>;
  participate_title?: Maybe<Scalars['String']>;
  path_image?: Maybe<Scalars['String']>;
  path_image_banner?: Maybe<Scalars['String']>;
  path_image_banner_mobile?: Maybe<Scalars['String']>;
  path_image_call?: Maybe<Scalars['String']>;
  path_image_mobile?: Maybe<Scalars['String']>;
  path_regulation?: Maybe<Scalars['String']>;
  primary_color?: Maybe<Scalars['String']>;
  secondary_color?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
};

export class UpdateQuestionVm {
  answers?: Maybe<Array<Maybe<UpdateAnswerVm>>>;
  id?: Maybe<Scalars['Guid']>;
  module?: Maybe<Scalars['String']>;
  module_level?: Maybe<Scalars['String']>;
  punctuation?: Maybe<Scalars['Int']>;
  punctuation_second?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  questionnaire_id?: Maybe<Scalars['Guid']>;
};

export class UpdateUserAdminVm {
  birthday?: Maybe<Scalars['DateTime']>;
  cpf?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Guid']>;
  name?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
};

export class UpdateUserVm {
  address?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  cellphone?: Maybe<Scalars['String']>;
  cellphone2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  document?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Guid']>;
  name?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export class User {
  token?: Maybe<Scalars['String']>;
  user?: Maybe<UserVm>;
};

export class UserAdmin {
  token?: Maybe<Scalars['String']>;
  userAdmin?: Maybe<UserAdminVm>;
};

export class UserAdminEntity {
  birthday?: Maybe<Scalars['DateTime']>;
  codeFirstAccess?: Maybe<Scalars['String']>;
  codeFirstAccessExpiration?: Maybe<Scalars['DateTime']>;
  cpf?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstAccess?: Maybe<Scalars['Boolean']>;
  firstAccessAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Guid'];
  name?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  resetPasswordExpiration?: Maybe<Scalars['DateTime']>;
  resetPasswordToken?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export class UserAdminPagination {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Maybe<UserAdminVm>>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class UserAdminVm {
  birthday?: Maybe<Scalars['DateTime']>;
  cpf?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstAccess?: Maybe<Scalars['Boolean']>;
  firstAccessAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Guid'];
  name?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export class UserAnswerPagination {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Maybe<UserAnswerVm>>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class UserAnswerVm {
  answer_id?: Maybe<Scalars['Guid']>;
  answer_title?: Maybe<Scalars['String']>;
  answered?: Maybe<Scalars['Boolean']>;
  answered_at?: Maybe<Scalars['DateTime']>;
  created_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Guid']>;
  ordination?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['Guid']>;
  question_title?: Maybe<Scalars['String']>;
  quiz_questionnaire_id?: Maybe<Scalars['Guid']>;
  quiz_questionnaire_title?: Maybe<Scalars['String']>;
  right_answer?: Maybe<Scalars['Boolean']>;
  right_answer_tentative?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Guid']>;
  user_name?: Maybe<Scalars['String']>;
};

export class UserEntity {
  address?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  cellphone?: Maybe<Scalars['String']>;
  cellphone2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  document?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_access?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Guid']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  profile_type?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  update_at?: Maybe<Scalars['DateTime']>;
  zipcode?: Maybe<Scalars['String']>;
};

export class UserPagination {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Maybe<UserVm>>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class UserProgressAnswer {
  answerAt?: Maybe<Scalars['DateTime']>;
  baseCamp?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  punctuation?: Maybe<Scalars['Int']>;
  questionId?: Maybe<Scalars['Guid']>;
  responseTime?: Maybe<Scalars['TimeOnly']>;
  rightAnswer?: Maybe<Scalars['Boolean']>;
};

export class UserProgressVm {
  baseUrlGame?: Maybe<Scalars['String']>;
  questionnaires?: Maybe<Array<Maybe<UserQuestionnaireProgress>>>;
  quizId?: Maybe<Scalars['Guid']>;
  totalPoints?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Guid']>;
};

export class UserQuestionnairePagination {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Maybe<UserQuestionnaireVm>>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class UserQuestionnaireProgress {
  answers?: Maybe<Array<Maybe<UserProgressAnswer>>>;
  id?: Maybe<Scalars['Guid']>;
  isFinish?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
};

export class UserQuestionnaireVm {
  finish?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Guid']>;
  is_finished?: Maybe<Scalars['Boolean']>;
  is_first?: Maybe<Scalars['Boolean']>;
  is_time_out?: Maybe<Scalars['Boolean']>;
  punctuation?: Maybe<Scalars['Int']>;
  questionnaire_title?: Maybe<Scalars['String']>;
  quiz_id?: Maybe<Scalars['Guid']>;
  quiz_questionnaire_id?: Maybe<Scalars['Guid']>;
  quiz_title?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
  time_out_at?: Maybe<Scalars['DateTime']>;
  user_document?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Guid']>;
  user_name?: Maybe<Scalars['String']>;
};

export class UserVm {
  address?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  cellphone?: Maybe<Scalars['String']>;
  cellphone2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  document?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_access?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Guid']>;
  name?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  profile_type?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type DefaultMessageFragment = { message?: string | null };

export type UserAdminFragment = { token?: string | null, userAdmin?: { id: any, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, nickName?: string | null, cpf?: string | null, photoUrl?: string | null, firstAccess?: boolean | null, firstAccessAt?: any | null, name?: string | null, email?: string | null, phone?: string | null, birthday?: any | null } | null };

export type UserAdminPaginationFragment = { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ id: any, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, nickName?: string | null, cpf?: string | null, photoUrl?: string | null, firstAccess?: boolean | null, firstAccessAt?: any | null, name?: string | null, email?: string | null, phone?: string | null, birthday?: any | null } | null> | null };

export type UserAdminVmFragment = { id: any, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, nickName?: string | null, cpf?: string | null, photoUrl?: string | null, firstAccess?: boolean | null, firstAccessAt?: any | null, name?: string | null, email?: string | null, phone?: string | null, birthday?: any | null };

export type CampaignVmFragment = { id?: any | null, title?: string | null, description?: string | null, initials?: string | null, path_image?: string | null, path_image_mobile?: string | null, path_image_banner?: string | null, path_image_banner_mobile?: string | null, path_image_call?: string | null, started_at?: any | null, finished_at?: any | null, active?: boolean | null, finished?: boolean | null, participate_title?: string | null, informative_title?: string | null, path_regulation?: string | null, owner?: number | null, primary_color?: string | null, secondary_color?: string | null };

export type UpdateCampaignVmFragment = { id?: any | null, title?: string | null, description?: string | null, initials?: string | null, path_image?: string | null, path_image_mobile?: string | null, path_image_banner?: string | null, path_image_banner_mobile?: string | null, path_image_call?: string | null, started_at?: any | null, finished_at?: any | null, participate_title?: string | null, informative_title?: string | null, path_regulation?: string | null, owner?: number | null, primary_color?: string | null, secondary_color?: string | null };

export type CampaignPaginationFragment = { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ id?: any | null, title?: string | null, description?: string | null, initials?: string | null, path_image?: string | null, path_image_mobile?: string | null, path_image_banner?: string | null, path_image_banner_mobile?: string | null, path_image_call?: string | null, started_at?: any | null, finished_at?: any | null, active?: boolean | null, finished?: boolean | null, participate_title?: string | null, informative_title?: string | null, path_regulation?: string | null, owner?: number | null, primary_color?: string | null, secondary_color?: string | null } | null> | null };

export type QuizListVmFragment = { id?: any | null, title?: string | null, description?: string | null, path_image?: string | null, path_image_mobile?: string | null, path_image_banner?: string | null, path_image_banner_mobile?: string | null, campaign_id?: any | null, path_link?: string | null, created_at?: any | null, started_at?: any | null, finished_at?: any | null, active?: boolean | null, finished?: boolean | null, is_second_chance?: boolean | null };

export type QuizPaginationFragment = { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ id?: any | null, title?: string | null, description?: string | null, path_image?: string | null, path_image_mobile?: string | null, path_image_banner?: string | null, path_image_banner_mobile?: string | null, campaign_id?: any | null, path_link?: string | null, created_at?: any | null, started_at?: any | null, finished_at?: any | null, active?: boolean | null, finished?: boolean | null, is_second_chance?: boolean | null } | null> | null };

export type QuizQuestionnaireListVmFragment = { id?: any | null, quiz_id?: any | null, quiz_title?: string | null, title?: string | null, punctuation?: number | null, response_time?: any | null, started_at?: any | null, finished_at?: any | null, ordination?: number | null };

export type QuizQuestionnairePaginationFragment = { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ id?: any | null, quiz_id?: any | null, quiz_title?: string | null, title?: string | null, punctuation?: number | null, response_time?: any | null, started_at?: any | null, finished_at?: any | null, ordination?: number | null } | null> | null };

export type QuestionnaireVmFragment = { id?: any | null, quiz_id?: any | null, title?: string | null, punctuation?: number | null, response_time?: any | null, started_at?: any | null, finished_at?: any | null, ordination?: number | null, module?: string | null, questions?: Array<{ id?: any | null, questionnaire_id?: any | null, questionnaire_title?: string | null, question?: string | null, module?: string | null, module_level?: string | null, punctuation?: number | null, punctuation_second?: number | null, answers?: Array<{ id?: any | null, question_id?: any | null, question_title?: string | null, answer?: string | null, ordination?: number | null, is_correct?: boolean | null, image_url?: string | null, width?: number | null, height?: number | null } | null> | null } | null> | null };

export type GetCampaignFilterQueryVariables = Exact<{
  input?: InputMaybe<Input_CampaignFilterInput>;
}>;


export type GetCampaignFilterQuery = { getCampaignFilter?: { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ id?: any | null, title?: string | null, description?: string | null, initials?: string | null, path_image?: string | null, path_image_mobile?: string | null, path_image_banner?: string | null, path_image_banner_mobile?: string | null, path_image_call?: string | null, started_at?: any | null, finished_at?: any | null, active?: boolean | null, finished?: boolean | null, participate_title?: string | null, informative_title?: string | null, path_regulation?: string | null, owner?: number | null, primary_color?: string | null, secondary_color?: string | null } | null> | null } | null };

export type GetCampaignQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCampaignQuery = { getCampaign?: Array<{ id?: any | null, title?: string | null } | null> | null };

export type CreateCampaignMutationVariables = Exact<{
  input?: InputMaybe<Input_CreateCampaignVm>;
}>;


export type CreateCampaignMutation = { createCampaign?: { message?: string | null } | null };

export type UpdateCampaignMutationVariables = Exact<{
  input?: InputMaybe<Input_UpdateCampaignVm>;
}>;


export type UpdateCampaignMutation = { updateCampaign?: { id?: any | null, title?: string | null, description?: string | null, initials?: string | null, path_image?: string | null, path_image_mobile?: string | null, path_image_banner?: string | null, path_image_banner_mobile?: string | null, path_image_call?: string | null, started_at?: any | null, finished_at?: any | null, participate_title?: string | null, informative_title?: string | null, path_regulation?: string | null, owner?: number | null, primary_color?: string | null, secondary_color?: string | null } | null };

export type DeleteCampaignMutationVariables = Exact<{
  id: Scalars['Guid'];
}>;


export type DeleteCampaignMutation = { deleteCampaign?: { message?: string | null } | null };

export type GetQuestionnaireFilterQueryVariables = Exact<{
  input?: InputMaybe<Input_QuizQuestionnaireFilter>;
}>;


export type GetQuestionnaireFilterQuery = { getQuestionnaireFilter?: { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ id?: any | null, quiz_id?: any | null, quiz_title?: string | null, title?: string | null, punctuation?: number | null, response_time?: any | null, started_at?: any | null, finished_at?: any | null, ordination?: number | null } | null> | null } | null };

export type QuestionnaireByQuizIdQueryVariables = Exact<{
  quizId: Scalars['Guid'];
  page: Scalars['Int'];
}>;


export type QuestionnaireByQuizIdQuery = { questionnaireByQuizId?: { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ id?: any | null, quiz_id?: any | null, quiz_title?: string | null, title?: string | null, punctuation?: number | null, response_time?: any | null, started_at?: any | null, finished_at?: any | null, ordination?: number | null } | null> | null } | null };

export type GetQuestionnaireByIdQueryVariables = Exact<{
  id: Scalars['Guid'];
}>;


export type GetQuestionnaireByIdQuery = { getQuestionnaireById?: { id?: any | null, quiz_id?: any | null, title?: string | null, punctuation?: number | null, response_time?: any | null, started_at?: any | null, finished_at?: any | null, ordination?: number | null, module?: string | null, questions?: Array<{ id?: any | null, questionnaire_id?: any | null, questionnaire_title?: string | null, question?: string | null, module?: string | null, module_level?: string | null, punctuation?: number | null, punctuation_second?: number | null, answers?: Array<{ id?: any | null, question_id?: any | null, question_title?: string | null, answer?: string | null, ordination?: number | null, is_correct?: boolean | null, image_url?: string | null, width?: number | null, height?: number | null } | null> | null } | null> | null } | null };

export type CreateQuestionnaireMutationVariables = Exact<{
  input: Input_CreateQuestionnaireVm;
}>;


export type CreateQuestionnaireMutation = { createQuestionnaire?: { message?: string | null } | null };

export type UpdateQuestionnaireMutationVariables = Exact<{
  input: Input_UpdateQuestionnaireVm;
}>;


export type UpdateQuestionnaireMutation = { updateQuestionnaire?: { id?: any | null, quiz_id?: any | null, title?: string | null, punctuation?: number | null, response_time?: any | null, started_at?: any | null, finished_at?: any | null, ordination?: number | null, module?: string | null, questions?: Array<{ id?: any | null, questionnaire_id?: any | null, questionnaire_title?: string | null, question?: string | null, module?: string | null, module_level?: string | null, punctuation?: number | null, punctuation_second?: number | null, answers?: Array<{ id?: any | null, question_id?: any | null, question_title?: string | null, answer?: string | null, ordination?: number | null, is_correct?: boolean | null, image_url?: string | null, width?: number | null, height?: number | null } | null> | null } | null> | null } | null };

export type DeleteQuestionnaireMutationVariables = Exact<{
  id: Scalars['Guid'];
}>;


export type DeleteQuestionnaireMutation = { deleteQuestionnaire?: { message?: string | null } | null };

export type GetQuizFilterQueryVariables = Exact<{
  input?: InputMaybe<Input_QuizListFilterInput>;
}>;


export type GetQuizFilterQuery = { getQuizFilter?: { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ id?: any | null, title?: string | null, description?: string | null, path_image?: string | null, path_image_mobile?: string | null, path_image_banner?: string | null, path_image_banner_mobile?: string | null, campaign_id?: any | null, path_link?: string | null, created_at?: any | null, started_at?: any | null, finished_at?: any | null, active?: boolean | null, finished?: boolean | null, is_second_chance?: boolean | null } | null> | null } | null };

export type CreateQuizMutationVariables = Exact<{
  input?: InputMaybe<Input_CreateQuizVm>;
}>;


export type CreateQuizMutation = { createQuiz?: { message?: string | null } | null };

export type UpdateQuizMutationVariables = Exact<{
  input?: InputMaybe<Input_UpdateQuizVm>;
}>;


export type UpdateQuizMutation = { updateQuiz?: { id?: any | null, title?: string | null, description?: string | null, path_image?: string | null, path_image_mobile?: string | null, path_image_banner?: string | null, path_image_banner_mobile?: string | null, campaign_id?: any | null, path_link?: string | null, created_at?: any | null, started_at?: any | null, finished_at?: any | null, active?: boolean | null, finished?: boolean | null, is_second_chance?: boolean | null } | null };

export type DeleteQuizMutationVariables = Exact<{
  id: Scalars['Guid'];
}>;


export type DeleteQuizMutation = { deleteQuiz?: { message?: string | null } | null };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { currentUserAdmin?: { id: any, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, nickName?: string | null, cpf?: string | null, photoUrl?: string | null, firstAccess?: boolean | null, firstAccessAt?: any | null, name?: string | null, email?: string | null, phone?: string | null, birthday?: any | null } | null };

export type UsersAdminQueryVariables = Exact<{
  data: Input_UserAdminFilterInput;
}>;


export type UsersAdminQuery = { usersAdmin?: { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ id: any, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, nickName?: string | null, cpf?: string | null, photoUrl?: string | null, firstAccess?: boolean | null, firstAccessAt?: any | null, name?: string | null, email?: string | null, phone?: string | null, birthday?: any | null } | null> | null } | null };

export type CreateUserMutationVariables = Exact<{
  data?: InputMaybe<Input_CreateUserAdminVm>;
}>;


export type CreateUserMutation = { createUserAdmin?: { message?: string | null } | null };

export type UpdateUserMutationVariables = Exact<{
  data?: InputMaybe<Input_UpdateUserAdminVm>;
}>;


export type UpdateUserMutation = { updateUserAdmin?: { id?: any | null, password?: string | null, nickName?: string | null, cpf?: string | null, photoUrl?: string | null, name?: string | null, email?: string | null, phone?: string | null, birthday?: any | null } | null };

export type DeleteUserMutationVariables = Exact<{
  data: Scalars['Guid'];
}>;


export type DeleteUserMutation = { deleteUserAdmin?: { message?: string | null } | null };

export type LoginMutationVariables = Exact<{
  cpf: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { loginAdmin?: { token?: string | null, userAdmin?: { id: any, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, nickName?: string | null, cpf?: string | null, photoUrl?: string | null, firstAccess?: boolean | null, firstAccessAt?: any | null, name?: string | null, email?: string | null, phone?: string | null, birthday?: any | null } | null } | null };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = { forgotPassword?: { message?: string | null } | null };

export type ResetPasswordMutationVariables = Exact<{
  data: Input_ResetPasswordInput;
}>;


export type ResetPasswordMutation = { resetPassword?: { message?: string | null } | null };

export type ChangePasswordMutationVariables = Exact<{
  data: Input_ChancePasswordInput;
}>;


export type ChangePasswordMutation = { changePassword?: { message?: string | null } | null };

export const DefaultMessageFragmentDoc = gql`
    fragment DefaultMessage on DefaultMessage {
  message
}
    `;
export const UserAdminVmFragmentDoc = gql`
    fragment UserAdminVM on UserAdminVM {
  id
  createdAt
  updatedAt
  deletedAt
  nickName
  cpf
  photoUrl
  firstAccess
  firstAccessAt
  name
  email
  phone
  birthday
}
    `;
export const UserAdminFragmentDoc = gql`
    fragment UserAdmin on UserAdmin {
  token
  userAdmin {
    ...UserAdminVM
  }
}
    ${UserAdminVmFragmentDoc}`;
export const UserAdminPaginationFragmentDoc = gql`
    fragment UserAdminPagination on UserAdminPagination {
  totalCount
  pageSize
  currentPage
  totalPage
  previousPage
  nextPage
  results {
    ...UserAdminVM
  }
}
    ${UserAdminVmFragmentDoc}`;
export const UpdateCampaignVmFragmentDoc = gql`
    fragment UpdateCampaignVM on UpdateCampaignVM {
  id
  title
  description
  initials
  path_image
  path_image_mobile
  path_image_banner
  path_image_banner_mobile
  path_image_call
  started_at
  finished_at
  participate_title
  informative_title
  path_regulation
  owner
  primary_color
  secondary_color
}
    `;
export const CampaignVmFragmentDoc = gql`
    fragment CampaignVM on CampaignVM {
  id
  title
  description
  initials
  path_image
  path_image_mobile
  path_image_banner
  path_image_banner_mobile
  path_image_call
  started_at
  finished_at
  active
  finished
  participate_title
  informative_title
  path_regulation
  owner
  primary_color
  secondary_color
}
    `;
export const CampaignPaginationFragmentDoc = gql`
    fragment CampaignPagination on CampaignPagination {
  totalCount
  pageSize
  currentPage
  totalPage
  previousPage
  nextPage
  results {
    ...CampaignVM
  }
}
    ${CampaignVmFragmentDoc}`;
export const QuizListVmFragmentDoc = gql`
    fragment QuizListVM on QuizListVM {
  id
  title
  description
  path_image
  path_image_mobile
  path_image_banner
  path_image_banner_mobile
  campaign_id
  path_link
  created_at
  started_at
  finished_at
  active
  finished
  is_second_chance
}
    `;
export const QuizPaginationFragmentDoc = gql`
    fragment QuizPagination on QuizPagination {
  totalCount
  pageSize
  currentPage
  totalPage
  previousPage
  nextPage
  results {
    ...QuizListVM
  }
}
    ${QuizListVmFragmentDoc}`;
export const QuizQuestionnaireListVmFragmentDoc = gql`
    fragment QuizQuestionnaireListVM on QuizQuestionnaireListVM {
  id
  quiz_id
  quiz_title
  title
  punctuation
  response_time
  started_at
  finished_at
  ordination
}
    `;
export const QuizQuestionnairePaginationFragmentDoc = gql`
    fragment QuizQuestionnairePagination on QuizQuestionnairePagination {
  totalCount
  pageSize
  currentPage
  totalPage
  previousPage
  nextPage
  results {
    ...QuizQuestionnaireListVM
  }
}
    ${QuizQuestionnaireListVmFragmentDoc}`;
export const QuestionnaireVmFragmentDoc = gql`
    fragment QuestionnaireVM on QuestionnaireVM {
  id
  quiz_id
  title
  punctuation
  response_time
  started_at
  finished_at
  ordination
  module
  questions {
    id
    questionnaire_id
    questionnaire_title
    question
    module
    module_level
    punctuation
    punctuation_second
    answers {
      id
      question_id
      question_title
      answer
      ordination
      is_correct
      image_url
      width
      height
    }
  }
}
    `;
export const GetCampaignFilterDocument = gql`
    query getCampaignFilter($input: Input_CampaignFilterInput) {
  getCampaignFilter(input: $input) {
    ...CampaignPagination
  }
}
    ${CampaignPaginationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCampaignFilterGQL extends Apollo.Query<GetCampaignFilterQuery, GetCampaignFilterQueryVariables> {
    document = GetCampaignFilterDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCampaignDocument = gql`
    query getCampaign {
  getCampaign {
    id
    title
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCampaignGQL extends Apollo.Query<GetCampaignQuery, GetCampaignQueryVariables> {
    document = GetCampaignDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateCampaignDocument = gql`
    mutation createCampaign($input: Input_CreateCampaignVM) {
  createCampaign(input: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCampaignGQL extends Apollo.Mutation<CreateCampaignMutation, CreateCampaignMutationVariables> {
    document = CreateCampaignDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCampaignDocument = gql`
    mutation updateCampaign($input: Input_UpdateCampaignVM) {
  updateCampaign(input: $input) {
    ...UpdateCampaignVM
  }
}
    ${UpdateCampaignVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCampaignGQL extends Apollo.Mutation<UpdateCampaignMutation, UpdateCampaignMutationVariables> {
    document = UpdateCampaignDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteCampaignDocument = gql`
    mutation deleteCampaign($id: Guid!) {
  deleteCampaign(id: $id) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCampaignGQL extends Apollo.Mutation<DeleteCampaignMutation, DeleteCampaignMutationVariables> {
    document = DeleteCampaignDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetQuestionnaireFilterDocument = gql`
    query getQuestionnaireFilter($input: Input_QuizQuestionnaireFilter) {
  getQuestionnaireFilter(input: $input) {
    ...QuizQuestionnairePagination
  }
}
    ${QuizQuestionnairePaginationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetQuestionnaireFilterGQL extends Apollo.Query<GetQuestionnaireFilterQuery, GetQuestionnaireFilterQueryVariables> {
    document = GetQuestionnaireFilterDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QuestionnaireByQuizIdDocument = gql`
    query questionnaireByQuizId($quizId: Guid!, $page: Int!) {
  questionnaireByQuizId(quizId: $quizId, page: $page) {
    ...QuizQuestionnairePagination
  }
}
    ${QuizQuestionnairePaginationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class QuestionnaireByQuizIdGQL extends Apollo.Query<QuestionnaireByQuizIdQuery, QuestionnaireByQuizIdQueryVariables> {
    document = QuestionnaireByQuizIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetQuestionnaireByIdDocument = gql`
    query getQuestionnaireById($id: Guid!) {
  getQuestionnaireById(id: $id) {
    ...QuestionnaireVM
  }
}
    ${QuestionnaireVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetQuestionnaireByIdGQL extends Apollo.Query<GetQuestionnaireByIdQuery, GetQuestionnaireByIdQueryVariables> {
    document = GetQuestionnaireByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateQuestionnaireDocument = gql`
    mutation createQuestionnaire($input: Input_CreateQuestionnaireVM!) {
  createQuestionnaire(input: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateQuestionnaireGQL extends Apollo.Mutation<CreateQuestionnaireMutation, CreateQuestionnaireMutationVariables> {
    document = CreateQuestionnaireDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateQuestionnaireDocument = gql`
    mutation updateQuestionnaire($input: Input_UpdateQuestionnaireVM!) {
  updateQuestionnaire(input: $input) {
    ...QuestionnaireVM
  }
}
    ${QuestionnaireVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateQuestionnaireGQL extends Apollo.Mutation<UpdateQuestionnaireMutation, UpdateQuestionnaireMutationVariables> {
    document = UpdateQuestionnaireDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteQuestionnaireDocument = gql`
    mutation DeleteQuestionnaire($id: Guid!) {
  deleteQuestionnaire(id: $id) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteQuestionnaireGQL extends Apollo.Mutation<DeleteQuestionnaireMutation, DeleteQuestionnaireMutationVariables> {
    document = DeleteQuestionnaireDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetQuizFilterDocument = gql`
    query getQuizFilter($input: Input_QuizListFilterInput) {
  getQuizFilter(input: $input) {
    ...QuizPagination
  }
}
    ${QuizPaginationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetQuizFilterGQL extends Apollo.Query<GetQuizFilterQuery, GetQuizFilterQueryVariables> {
    document = GetQuizFilterDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateQuizDocument = gql`
    mutation createQuiz($input: Input_CreateQuizVM) {
  createQuiz(input: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateQuizGQL extends Apollo.Mutation<CreateQuizMutation, CreateQuizMutationVariables> {
    document = CreateQuizDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateQuizDocument = gql`
    mutation updateQuiz($input: Input_UpdateQuizVM) {
  updateQuiz(input: $input) {
    ...QuizListVM
  }
}
    ${QuizListVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateQuizGQL extends Apollo.Mutation<UpdateQuizMutation, UpdateQuizMutationVariables> {
    document = UpdateQuizDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteQuizDocument = gql`
    mutation deleteQuiz($id: Guid!) {
  deleteQuiz(id: $id) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteQuizGQL extends Apollo.Mutation<DeleteQuizMutation, DeleteQuizMutationVariables> {
    document = DeleteQuizDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUserAdmin {
    ...UserAdminVM
  }
}
    ${UserAdminVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserGQL extends Apollo.Query<CurrentUserQuery, CurrentUserQueryVariables> {
    document = CurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UsersAdminDocument = gql`
    query UsersAdmin($data: Input_UserAdminFilterInput!) {
  usersAdmin(input: $data) {
    ...UserAdminPagination
  }
}
    ${UserAdminPaginationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UsersAdminGQL extends Apollo.Query<UsersAdminQuery, UsersAdminQueryVariables> {
    document = UsersAdminDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserDocument = gql`
    mutation CreateUser($data: Input_CreateUserAdminVM) {
  createUserAdmin(input: $data) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserGQL extends Apollo.Mutation<CreateUserMutation, CreateUserMutationVariables> {
    document = CreateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserDocument = gql`
    mutation UpdateUser($data: Input_UpdateUserAdminVM) {
  updateUserAdmin(input: $data) {
    id
    password
    nickName
    cpf
    photoUrl
    name
    email
    phone
    birthday
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    document = UpdateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteUserDocument = gql`
    mutation DeleteUser($data: Guid!) {
  deleteUserAdmin(id: $data) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUserGQL extends Apollo.Mutation<DeleteUserMutation, DeleteUserMutationVariables> {
    document = DeleteUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginDocument = gql`
    mutation Login($cpf: String!, $password: String!) {
  loginAdmin(cpf: $cpf, password: $password) {
    ...UserAdmin
  }
}
    ${UserAdminFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($email: String!) {
  forgotPassword(email: $email) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ForgotPasswordGQL extends Apollo.Mutation<ForgotPasswordMutation, ForgotPasswordMutationVariables> {
    document = ForgotPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResetPasswordDocument = gql`
    mutation ResetPassword($data: Input_ResetPasswordInput!) {
  resetPassword(input: $data) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ResetPasswordGQL extends Apollo.Mutation<ResetPasswordMutation, ResetPasswordMutationVariables> {
    document = ResetPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChangePasswordDocument = gql`
    mutation ChangePassword($data: Input_ChancePasswordInput!) {
  changePassword(input: $data) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ChangePasswordGQL extends Apollo.Mutation<ChangePasswordMutation, ChangePasswordMutationVariables> {
    document = ChangePasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }