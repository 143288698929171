import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable()
export class ServiceBase {

  public BASE_URL = environment.base_url;
  // private storeDataSource = new BehaviorSubject<Store>(new Store());
  // store = this.storeDataSource.asObservable();

  constructor(public http: HttpClient) {
  }

  public getToken() {
    return localStorage.getItem('token') as string;
  }

  public header() {
    const customHeaders: HttpHeaders = new HttpHeaders();
    customHeaders.append('Content-Type', 'application/json');

    const token = localStorage.getItem('token') as string;

    if (token != null) {
      customHeaders.append('Authorization', 'Bearer ' + token);
    }
    return {headers: customHeaders};
  }

  // updateStore(param: Store) {
  //   this.storeDataSource.next(param);
  // }

  getExcel(model: any) {
    const url = `${this.BASE_URL}/store/search-store/excel`;
    return this.http.post(url, model);
  }

  getExcelSchedule(model: any) {
    const url = `${this.BASE_URL}/user-schedule/search-user-schedule/excel`;
    return this.http.post(url, model);
  }


  uploadImport(name: any, file: any) {
    const url = `${this.BASE_URL}/${name}/excel-import`;
    return new Observable((observer) => {
      // tslint:disable-next-line:one-variable-per-declaration
      const formData: FormData = new FormData(),
        xhr: XMLHttpRequest = new XMLHttpRequest();
      if (file != null) {
        formData.append('file', file, file.name);
      }
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.upload.onprogress = (event) => {
        const progress = Math.round(event.loaded / event.total * 100);
      };
      xhr.open('POST', url, true);
      xhr.setRequestHeader('Authorization', 'Bearer ' + this.getToken());
      xhr.send(formData);
    });
  }

  uploadImportsClass(nameRoute: string, id: any, file: any) {
    const url = `${this.BASE_URL}/upload/${nameRoute}/${id}`;
    return new Observable((observer) => {
      // tslint:disable-next-line:one-variable-per-declaration
      const formData: FormData = new FormData(),
        xhr: XMLHttpRequest = new XMLHttpRequest();
      if (file != null) {
        formData.append('file', file, file.name);
      }
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.upload.onprogress = (event) => {
        const progress = Math.round(event.loaded / event.total * 100);
      };
      xhr.open('POST', url, true);
      xhr.setRequestHeader('Authorization', 'Bearer ' + this.getToken());
      xhr.send(formData);
    });
  }


}
